.company-card {
    margin: 2rem auto;
    padding: 1.2rem;
    background-color: var(--background-color-secondary);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 22rem;
}

.company-card img {
    max-height: 320px;
    max-width: 75%;
    margin: 0 auto;
    padding: 8%;
}

.company-card h2 {
    font-weight: normal;
}

.company-card p {
    text-align: center;
    color: var(--text-color-secondary);
}