* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

.preload-wrapper {
    width: 100%;
    height: 100vh;
}

#logo {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 20%;
    max-width: 65%;
    transform: translate(-50%, -50%);
}

#logo path:nth-child(1) {
    stroke-dasharray: 592;
    stroke-dashoffset: 592;
    animation: preLoadAnimation 8s ease forwards;
}

#logo path:nth-child(2) {
    stroke-dasharray: 844;
    stroke-dashoffset: 844;
    animation: preLoadAnimation 8s ease forwards 100ms;
}

#logo path:nth-child(3) {
    stroke-dasharray: 410;
    stroke-dashoffset: 410;
    animation: preLoadAnimation 8s ease forwards 200ms;
}

#logo path:nth-child(4) {
    stroke-dasharray: 703.46;
    stroke-dashoffset: 703.46;
    animation: preLoadAnimation 8s ease forwards 300ms;
}

#logo path:nth-child(5) {
    stroke-dasharray: 704;
    stroke-dashoffset: 704;
    animation: preLoadAnimation 8s ease forwards 400ms;
}

@keyframes preLoadAnimation {
    from {
        fill: transparent;
    }
    to {
        stroke-dashoffset: 0;
        fill: #707070;
    }
}