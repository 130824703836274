.banner {
    width: 100%;
    background-color: var(--accent-color-primary);
    padding: 8rem 12rem;
    text-align: center;
    color: white;
}

.banner h2 {
    font-size: var(--font-size-xlarge);
    font-weight: 600;
}

.banner p {
    font-size: var(--font-size-medium);
    padding-top: 1rem;
}

@media screen and (max-width: 772px) {
    .banner {
        padding: 6rem 2rem;
    }
}